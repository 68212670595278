jQuery(function () {
    function checkVisibility() {
        jQuery(".hidden").each(function () {
            let element = $(this);
            let scrollPosition = jQuery(window).scrollTop() + jQuery(window).height() * 0.9; // Sichtbarkeitsgrenze anpassen

            if (element.offset().top < scrollPosition) {
                element.removeClass("hidden").addClass("animate__animated animate__fadeIn");
                element.style.setProperty('--animate-duration', '1s');
            }
        });

        jQuery(".hidden-flip").each(function () {
            let element = $(this);
            let scrollPosition = jQuery(window).scrollTop() + jQuery(window).height() * 0.9; // Sichtbarkeitsgrenze anpassen

            if (element.offset().top < scrollPosition) {
                element.removeClass("hidden-flip").addClass("animate__animated animate__flipInX");
                element.style.setProperty('--animate-duration', '1s');
            }
        });
    }

    // Beim Scrollen überprüfen
    jQuery(window).on("scroll", checkVisibility);

    // Direkt prüfen, falls Elemente schon sichtbar sind
    checkVisibility();

});

window.scrollTo({
    top: 0,
    behavior: 'smooth'
});

jQuery(document).ready(function($) {
    let currentPage = 1;

    function loadPosts(page) {
        $.ajax({
            url: ajaxpagination.ajax_url,
            type: 'post',
            data: {
                action: 'ajax_pagination',
                page: page
            },
            success: function(response) {
                if (response.trim() !== '') {
                    $('#post-container').html(response); // Ersetzt den Inhalt mit den neuen Beiträgen
                    currentPage = page;
                    updateButtons(); // Aktualisiert die Button-Sichtbarkeit
                }
            }
        });
    }

    function updateButtons() {
        // "Zurück"-Button nur anzeigen, wenn wir uns nicht auf Seite 1 befinden
        $('#prev-page').toggle(currentPage > 2); 
    }

    $('#next-page').on('click', function(e) {
        e.preventDefault();
        loadPosts(currentPage + 1);
    });

    $('#prev-page').on('click', function(e) {
        e.preventDefault();
        if (currentPage > 1) {
            loadPosts(currentPage - 1);
        }
    });

    // Initiale Button-Sichtbarkeit prüfen
    updateButtons();
});

document.addEventListener('wpcf7submit', function (event) {
    if (typeof _paq === 'undefined' || !_paq.push) return;

    // Optional: DSGVO-Einwilligung prüfen
    if (typeof window.matomoConsentGranted !== 'undefined' && !window.matomoConsentGranted) return;

    // Formularname aus dem Contact Form 7 Event holen
    var formId = event.detail.contactFormId || 'Unbekannt';
    var formName = 'Formular-ID: ' + formId;

    // Matomo Event senden
    _paq.push(['trackEvent', 'Formular', 'Absenden', formName]);

  }, false);



//document.addEventListener('wpcf7mailsent', function (event) {
document.addEventListener( 'wpcf7submit', function( event ) {
    if (typeof _paq === 'undefined' || !_paq.push) return;


     // Optional: nur nach Cookie-Zustimmung
     if (typeof window.matomoConsentGranted !== 'undefined' && !window.matomoConsentGranted) return;


     if (event.detail.contactFormId == '423a6ed') {
      //Track Karriereportal Form
      _paq.push(['trackGoal', 1]);

    }

    if (event.detail.contactFormId == '121c3c9') {
        //Track KMU Landingpage Form
        _paq.push(['trackGoal', 3]);

    }

    if (event.detail.contactFormId == 'c22de3f') {
        //Track Kontakt Form
        _paq.push(['trackGoal', 2]);

    }

  }, false);

//ScrollDepth

window.addEventListener('scroll', function () {
  if (typeof _paq === 'undefined' || !_paq.push) return;

  const scrollTop = window.scrollY;
  const docHeight = document.documentElement.scrollHeight - window.innerHeight;
  
  // Division durch Null vermeiden
  if (docHeight <= 0) return;

  const scrollPercent = (scrollTop / docHeight) * 100;

  // Tracking für verschiedene Prozente
  [25, 50, 75, 100].forEach(percent => {
    if (scrollPercent >= percent && !window[`scrollSent${percent}`]) {
      _paq.push(['trackEvent', 'Scroll', `${percent}%`, window.location.pathname]);
      window[`scrollSent${percent}`] = true;
    }
  });
});
